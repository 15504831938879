import { render, staticRenderFns } from "./PeriodicReportList.vue?vue&type=template&id=36ca9c1d&"
import script from "./PeriodicReportList.vue?vue&type=script&lang=js&"
export * from "./PeriodicReportList.vue?vue&type=script&lang=js&"
import style0 from "./PeriodicReportList.vue?vue&type=style&index=0&id=36ca9c1d&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QDialog,QCard,QIcon,QTooltip,QSpace,QBtn,QBtnToggle,QTable,QInnerLoading,QTd,QCardSection,QCardActions,QSpinnerTail,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QIcon,QTooltip,QSpace,QBtn,QBtnToggle,QTable,QInnerLoading,QTd,QCardSection,QCardActions,QSpinnerTail})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
